.fc-datagrid-cell-frame-liquid {
  height: 100%;
}

.fc-liquid-hack {
  & .fc-datagrid-cell-frame-liquid {
    @include liquid-absolute-override;
  }
}

.fc {
  & .fc-datagrid-header {
    & .fc-datagrid-cell-frame {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  & .fc-datagrid-cell-resizer {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
  }

  & .fc-datagrid-cell-cushion {
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
  }

  & .fc-datagrid-expander {
    cursor: pointer;
    opacity: 0.65;

    & .fc-icon {
      display: inline-block;
      width: 1em;
    }
  }

  & .fc-datagrid-expander-placeholder {
    cursor: auto;
  }

  & .fc-resource-timeline-flat {
    & .fc-datagrid-expander-placeholder {
      display: none;
    }
  }
}

.fc-direction-ltr .fc-datagrid-cell-resizer {
  right: -3px;
}
.fc-direction-rtl .fc-datagrid-cell-resizer {
  left: -3px;
}

.fc-direction-ltr .fc-datagrid-expander {
  margin-right: 3px;
}
.fc-direction-rtl .fc-datagrid-expander {
  margin-left: 3px;
}
