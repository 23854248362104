/* This file is necessary to set styles to react-dropdown-select components, 
a better option was use Styled from @emotion library,
but unfortunately it doesn't works with generic components */

.light-theme {
  --content-bg-color: var(--chakra-colors-white);
  --content-border-color: var(--chakra-colors-gray-300);
}

.dark-theme {
  --content-bg-color: var(--chakra-colors-whiteAlpha-200);
  --content-border-color: var(--chakra-colors-whiteAlpha-300);
}

.light-theme,
.dark-theme {
  border-radius: 6px !important;
  background-color: var(--content-bg-color);
}

.light-theme > .react-dropdown-select-dropdown-handle,
.dark-theme > .react-dropdown-select-dropdown-handle {
  margin-top: 2px;
}

.light-theme > .react-dropdown-select-dropdown,
.dark-theme > .react-dropdown-select-dropdown {
  position: absolute;
  width: 320px !important;
  flex-direction: column;
  border-radius: 4px;
  overflow: auto;
  z-index: 10;
}
