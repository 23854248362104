.calendar-datepicker .fc table {
  font-size: 0.95em;
}
.calendar-datepicker .fc-daygrid-day-top {
  justify-content: center;
}

.calendar-datepicker .fc .fc-toolbar-title {
  font-size: 1em;
  padding-left: 1em;
}

/* hide event section of the cell */
.calendar-datepicker .fc .fc-daygrid-day-events {
  min-height: 0 !important;
  min-width: 0 !important;
}

.calendar-datepicker .fc-daygrid-event-harness {
  min-height: 0 !important;
  height: 0 !important;
}

.calendar-datepicker .fc .fc-daygrid-day-top a {
  cursor: pointer;
}

.calendar-datepicker .fc table,
.calendar-datepicker .fc table td,
.calendar-datepicker .fc table th {
  border: 0;
}

.calendar-datepicker .fc .fc-button-group button {
  padding: 0.25em;
  padding-left: 0.33em;
  padding-right: 0.33em;
  background: var(--chakra-colors-gray-100);
  color: var(--chakra-colors-current);
  border: 0;
  font-size: 0.8125em;
  line-height: 1;
}

.calendar-datepicker .fc .fc-button-group button:hover {
  background-color: var(--chakra-colors-gray-200);
}

.calendar-datepicker .fc-daygrid-day {
  background-color: transparent;
}

.calendar-datepicker .fc-day-today {
  background-color: transparent !important;
}

html[data-theme="dark"] .calendar-datepicker .fc .fc-button-group button {
  background-color: var(--chakra-colors-whiteAlpha-200);
}

html[data-theme="dark"] .calendar-datepicker .fc .fc-button-group button:hover {
  background-color: var(--chakra-colors-whiteAlpha-300);
}
