.fc {
  & .fc-resource-timeline-divider {
    width: 3px;
    cursor: col-resize;
  }

  & .fc-resource-group {
    font-weight: inherit;
    text-align: inherit;
  }

  & .fc-resource-timeline {
    & .fc-resource-group:not([rowspan]) {
      background: var(--fc-neutral-bg-color);
    }
  }

  & .fc-timeline-lane-frame {
    position: relative;
  }

  & .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    box-sizing: content-box;
    padding-bottom: 10px;
  }
}

.fc-timeline-body-expandrows {
  & td.fc-timeline-lane {
    position: relative;
  }
  & .fc-timeline-lane-frame {
    position: static;
  }
}
