.light-theme-rich-text-editor {
  --light-gray: var(--chakra-colors-gray-200);
  --gray: var(--chakra-colors-gray-300);
  --primary-button: var(--chakra-colors-blue-500);
  --secondary-button: var(--chakra-colors-gray-200);
  --popup-background: var(--chakra-colors-white);
  --text: var(--chakra-colors-black);
  --negative-text: var(--chakra-colors-white);
  --editor-bg-color: var(--chakra-colors-white);
  --toolbar-color: var(--chakra-colors-gray-200);
  --toolbar-bottom: none;
  --toolbar-icons-filter-invert: 0%;
  --webkit-var-scrollbar-bgcolor: var(--chakra-colors-gray-100);
  --webkit-var-scrollbar-thumb-bgcolor: var(--chakra-colors-gray-300);
  --modal-input-border-color: var(--chakra-colors-gray-300);
  --rdw-option-active: var(--chakra-colors-gray-300);
  --rdw-editor-wrapper-border-color-default: var(--chakra-colors-gray-100);
  --rdw-editor-wrapper-border-color: var(--chakra-colors-gray-300);
}
.dark-theme-rich-text-editor {
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --primary-button: var(--chakra-colors-blue-200);
  --secondary-button: var(--chakra-colors-gray-400);
  --popup-background: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --negative-text: var(--chakra-colors-black);
  --editor-bg-color: var(--chakra-colors-transparent);
  --toolbar-color: transparent;
  --toolbar-bottom: 1px solid #4e5663;
  --toolbar-icons-filter-invert: 100%;
  --webkit-var-scrollbar-bgcolor: var(--chakra-colors-gray-500);
  --webkit-var-scrollbar-thumb-bgcolor: var(--chakra-colors-gray-300);
  --modal-input-border-color: var(--chakra-colors-whiteAlpha-300);
  --rdw-option-active: var(--chakra-colors-whiteAlpha-300);
  --rdw-editor-wrapper-border-color-default: var(
    --chakra-colors-whiteAlpha-300
  );
  --rdw-editor-wrapper-border-color: var(--chakra-colors-whiteAlpha-400);
}

.rdw-editor-wrapper {
  z-index: 0;
  border-radius: 6px;
  border: 1px solid;
  border-color: var(
    --rdw-editor-wrapper-border-color-default
  ) !important; /* Used to overwrite the default library styles */
}

.rdw-editor-wrapper:hover {
  border-color: var(
    --rdw-editor-wrapper-border-color
  ) !important; /* Used to overwrite the default library styles */
}

.rdw-editor-toolbar {
  border: 0px;
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  border-bottom: var(--toolbar-bottom);
  background-color: var(--toolbar-color);
}

.rdw-option-wrapper {
  background-color: transparent;
  border: 0px;
  border-radius: 4px;
  filter: invert(var(--toolbar-icons-filter-invert));
  opacity: 0.65;
}

.rdw-option-wrapper:hover {
  box-shadow: none;
  opacity: 1;
}

.rdw-option-active {
  opacity: 1;
  box-shadow: none;
  background-color: var(--rdw-option-active);
}

.rdw-editor-main {
  z-index: 0;
  /* min-height: 60px; */
  max-height: 110px;
  border-radius: 0 0 6px 6px;
  background-color: var(--editor-bg-color);
}

.rdw-editor-main::-webkit-scrollbar {
  width: 8px;
  border-radius: 6px;
  background-color: var(--webkit-var-scrollbar-bgcolor, #edf2f7);
}

.rdw-editor-main::-webkit-scrollbar-thumb {
  width: 12px;
  border-radius: 6px;
  background-color: var(--webkit-var-scrollbar-thumb-bgcolor, #a0aec0);
}

.public-DraftEditor-content {
  min-height: 60px;
}

/* remove min-height in readonly mode, this is for content that is only 1 line */
.public-DraftEditor-content[contenteditable="false"] {
  min-height: 10px;
}

.css-1khbh7z {
  z-index: 2 !important;
}

.css-1hb7zxy-IndicatorsContainer > div {
  background-color: transparent;
}

.css-1hb7zxy-IndicatorsContainer > hr {
  display: none;
}

.rdw-link-modal {
  left: -130px;
  box-shadow: none;
  border-radius: 4px;
  border: "1px solid";
  left: calc(100% - 180px);
  border-color: var(--light-gray);
  background-color: var(--popup-background);
}

.rdw-link-modal-target-option {
  display: none;
}

.rdw-link-modal-btn {
  border-radius: 6px;
}

.rdw-link-modal-input {
  border-radius: 4px;
  background-color: var(--popup-background);
  border-color: var(--modal-input-border-color);
}

.rdw-link-modal-btn:first-child {
  border: none;
  color: var(--negative-text);
  background-color: var(--primary-button);
}

.rdw-link-modal-btn:last-child {
  border: none;
  color: var(--chakra-colors-black);
  background-color: var(--secondary-button);
}

.rdw-emoji-modal {
  max-height: 62px;
  left: -200px;
  box-shadow: none;
  min-width: 240px;
  border-radius: 4px;
  border: "1px solid";
  left: calc(100% - 240px);
  border-color: var(--light-gray);
  background-color: var(--popup-background);
}

.rdw-link-modal-btn:hover {
  box-shadow: none;
}

.DraftEditor-editorContainer {
  z-index: 0;
  position: unset;
}

.DraftEditor-root {
  position: unset;
}

.public-DraftStyleDefault-block {
  margin: 2px 12px;
}

/* remove margin in readonly mode, so it acts like text */
.public-DraftEditor-content[contenteditable="false"]
  .public-DraftStyleDefault-block {
  margin: unset;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 4px 10px;
}

.public-DraftStyleDefault-ltr > span {
  word-break: break-word;
}

.rdw-suggestion-dropdown {
  position: absolute;
  border: none;
  float: inline-end;
  width: max-content;
  border-radius: 4px;
  box-shadow: var(
    --ds-shadow-overlay,
    0 0 1px rgba(9, 30, 66, 0.31),
    0 4px 8px -2px rgba(9, 30, 66, 0.25)
  );
  padding: 4px 0px;
  max-height: 380px;
}

.rdw-suggestion-wrapper {
  position: unset;
}

.public-DraftStyleDefault-block {
  position: unset;
}

.chakra-form-control {
  position: unset;
}

.rdw-suggestion-option {
  border: none;
}

.rdw-suggestion-option:hover {
  background-color: #e2e8f0;
  cursor: pointer;
}

.rdw-mention-link {
  border: 1px solid transparent;
  background: #216ba5;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  padding: 0px 0.3em 1px 0.23em;
  font-size: var(--chakra-fontSizes-sm);
  pointer-events: none;
}

.public-DraftStyleDefault-unorderedListItem {
  position: unset;
}

/* Styles mobile devices */
@media (min-width: 343px) and (max-width: 377px) {
  .rdw-link-modal {
    left: calc(100% - 220px);
  }
}

/* Styles Galaxy fold */
@media (max-width: 342px) {
  .rdw-link-modal {
    left: calc(100% - 220px);
  }
  .rdw-emoji-modal {
    left: calc(100% - 100px);
  }
}
