html[data-theme="dark"] {
  --fc-border-color: var(--chakra-colors-gray-700);
}

html[data-theme="light"] {
  --fc-border-color: #ddd;
}

.oh-planning-scheduling .fc {
  & .fc-resource-timeline-divider {
    width: 0px;
    cursor: none;
    border-width: 0px;
  }

  & .fc-timeline-slot {
    border-bottom: 0;
  }

  & .fc-scrollgrid {
    border-width: 0px 0px 0px 1px;
    border-radius: 10px 0px 0px 0px;
    border-color: var(--fc-border-color);
  }
  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-year-lane {
    border-color: var(--fc-border-color);
    border-width: 0;
  }

  &
    td.fc-timeline-slot.fc-timeline-slot-lane.custom-year-lane[data-date$="01"] {
    border-color: var(--fc-border-color);
    border-width: 1px;
  }

  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-week-lane {
    border-color: white;
    border-width: 0;
  }

  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-week-lane.fc-day-sun {
    border-width: 1px;
    border-color: var(--fc-border-color);
  }

  & .fc-timeline-now-indicator-arrow.project-start {
    border-color: green transparent green transparent;
    border-width: 7px 6px 0 6px;
  }

  & .fc-timeline-now-indicator-line.project-start {
    border-color: green;
    border-width: 0 0 0 2px;
  }

  & .fc-timeline-now-indicator-arrow.project-end {
    border-color: orange transparent orange transparent;
    border-width: 7px 6px 0 6px;
  }

  & .fc-timeline-now-indicator-line.project-end {
    border-color: orange;
    border-width: 0 0 0 2px;
  }

  & .fc-timeline-lane.fc-resource.custom-row {
    height: 6.2rem;
    border-color: transparent;
  }

  & .fc-timeline-lane-frame {
    height: 6.2rem !important;
  }

  &
    .fc-scrollgrid-section-body
    td:nth-child(1)
    .fc-scroller-harness.fc-scroller-harness-liquid
    .fc-scroller.fc-scroller-liquid-absolute {
    bottom: -15px;
    padding-bottom: 15px;
  }

  & .oh-resource-area-header {
    border-color: transparent;
  }

  & .oh-resource-label {
    border-color: transparent;
    font-size: var(--chakra-fontSizes-xs);

    .fc-datagrid-cell-cushion {
      padding: 8px 5px 8px 0px;
      text-align: right;
    }
  }

  &
    .fc-scrollgrid-section.fc-scrollgrid-section-header
    th:first-child
    .fc-scroller-harness {
    border-color: var(--fc-border-color);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-radius: 10px 0px 0px 0px !important;
  }

  & .fc-timeline-event.fc-h-event.project-request {
    border: none;
    padding: 0;
    background-color: transparent;
  }

  & .fc-timeline-header .fc-timeline-header-row:nth-child(1) .fc-day-disabled {
    background-color: var(--chakra-colors-chakra-body-bg);
  }

  & .fc-timeline-header-row .fc-timeline-slot:last-child a {
    overflow: hidden !important;
    text-overflow: clip;
  }

  & .fc-license-message {
    display: none;
  }
}

html[data-theme="dark"] {
  .oh-planning-scheduling .fc {
    & .fc-day-disabled {
      background-color: var(--chakra-colors-gray-700);
    }

    & .custom-year-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-gray-800);
      }
    }

    & .custom-week-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-gray-800);
      }
    }
  }
}

html[data-theme="light"] {
  .oh-planning-scheduling .fc {
    & .fc-day-disabled {
      background-color: var(--chakra-colors-gray-100);
    }

    & .custom-year-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-chakra-body-bg);
      }
    }

    & .custom-week-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-chakra-body-bg);
      }
    }
  }
}
