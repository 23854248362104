.mini-calendar .fc table {
  font-size: 0.95em;
}
.mini-calendar .fc-daygrid-day-top {
  justify-content: center;
}

.mini-calendar .fc .fc-toolbar-title {
  font-size: 1em;
  padding-left: 1em;
}

/* hide event section of the cell */
.mini-calendar .fc .fc-daygrid-day-events {
  display: none;
}

.mini-calendar .fc .fc-daygrid-day-top a {
  cursor: pointer;
}

.mini-calendar .fc table,
.mini-calendar .fc table td,
.mini-calendar .fc table th {
  border: 0;
}
#selectedWeek {
  background-color: var(--chakra-colors-blue-300);
}
#selectedWeek td:first-of-type {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
#selectedWeek td:last-of-type {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.mini-calendar .fc .fc-button-group button {
  padding: 0.25em;
  padding-left: 0.33em;
  padding-right: 0.33em;
  background: var(--chakra-colors-gray-100);
  color: var(--chakra-colors-current);
  border: 0;
  font-size: 0.8125em;
  line-height: 1;
}

.mini-calendar .fc .fc-button-group button:hover {
  background-color: var(--chakra-colors-gray-200);
}

html[data-theme="dark"] .mini-calendar .fc .fc-button-group button {
  background-color: var(--chakra-colors-whiteAlpha-200);
}

html[data-theme="dark"] .mini-calendar .fc .fc-button-group button:hover {
  background-color: var(--chakra-colors-whiteAlpha-300);
}
