@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");

.react-dropdown-select-dropdown {
  border: 0 !important;
}

.chakra-ui-dark .react-dropdown-select-dropdown {
  border: 0 !important;
  background-color: var(--chakra-colors-gray-700);
}

@keyframes ai-sparkle-light {
  0% {
    color: inherit;
  }
  50% {
    color: var(--chakra-colors-blue-500);
  }
  100% {
    color: inherit;
  }
}

@keyframes ai-sparkle-dark {
  0% {
    color: inherit;
  }

  50% {
    color: var(--chakra-colors-blue-200);
  }
  100% {
    color: inherit;
  }
}

.ai-sparkle-animation {
  animation: ai-sparkle-light 3s infinite linear;
}

.chakra-ui-dark .ai-sparkle-animation {
  animation: ai-sparkle-dark 3s infinite linear;
}
